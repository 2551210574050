import React from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import bird2 from '../assets/images/birds/bird3.png';
import Button from '../components/button';
// tabs

import 'react-tabs/style/react-tabs.css';

// transitionimage
import transitionimage from '../assets/images/transition.jpg';
// travelines
import Travelline from '../components/travellines/Travelline1';

const Page = styled.div`
  margin-top: 7.5vw;

  .siteWrapper {
    grid-column: 1 / span 24;
    @media (min-width: 768px) {
      grid-column: 5 / span 16;
    }
    .page-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      @media (min-width: 960px) {
        grid-template-columns: repeat(1, 1fr);
      }
      grid-template-rows: masonry;
      grid-gap: 30px;
    }
  }
  header {
    grid-column: 5 / span 16;
    padding: 3.5vw 0 0 0;
    .back {
      margin-bottom: 20px;
      display: block;
      font-size: 1em;
    }
    .intro-text {
      max-width: 400px;
      .title {
        text-transform: uppercase;
        font-size: 1.2rem;
        margin-top: 5px;
      }
      .sub-heading {
        font-size: 1.2rem;
      }
      h1 {
        font-size: 3em;
        padding-bottom: 10px;
      }
    }
  }

  min-height: 100vh;

  .tab-area {
    .react-tabs__tab {
      border-radius: 0;
      font-family: 'IBM Semi-Bold 600', sans-serif;
      font-size: 1em;
    }
    .react-tabs__tab--selected {
      background: var(--olive);
      color: var(--beige);
      border: 1px solid var(--grey);
    }
  }
`;

const Panel = styled.div`
  padding: 10px 10px;
  line-height: 1.2;
`;

const Bird = styled.div`
  position: absolute;
  left: -70px;
  top: 100px;

  z-index: 0;
  width: 40vw;
  max-width: 400px;
  height: auto;
  opacity: 0.7;
  transform: translateY(-50%) rotate(0deg);
`;

const transitionImageDiv = `url('${transitionimage}') center / cover  no-repeat fixed padding-box content-box white`;

export default function Experiences() {
  return (
    <Page>
      <Bird>
        <img src={bird2} alt="" />
      </Bird>
      <div className="SiteContainer">
        <div className="siteWrapper">
          <header>
            <div className="intro-text">
              <AniLink
                class="link back b-i"
                to="/"
                cover
                direction="down"
                activeClassName="active"
                bg={transitionImageDiv}
              >
                Back Home
              </AniLink>
              <h1>The Treasure Hunt</h1>
              <p className="sub-heading b-i" />
              <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSck_eedNzeLurhoY42u3umWRMpkK8V7ym31vUUGY7P2AxmnjQ/viewform?embedded=true"
                width="100%"
                height="647"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
              >
                Loading…
              </iframe>
            </div>
          </header>
        </div>
      </div>
    </Page>
  );
}
